import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "quantity", "kcal", "kcal_total", "btn_food_list", "btn_food_custom", "food_list", "food_custom", "food_custom_unit", "chart", "week_view"]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    let diaryController = this;

    if(document.querySelector("#entry_food")) {
      var food = $("#entry_food").selectize({
        sortField: "text",
      });
      var food_select = food[0].selectize

      food_select.on('change', () => {
        let value = food_select.getValue();
        diaryController.getFoodDetails(value);
      });
    }

    if(this.hasChartTarget) {
      this.loadData();
    }
  }

  getFoodDetails(id) {
    let diaryController = this;
    Rails.ajax({
      url: `/${this.locale}/diary/food/${id}`,
      type: "get",
      data: "",
      success: function(data) {
        diaryController.quantityTarget.value = data.unit_amount;
        diaryController.kcalTarget.value = data.kcal;
        diaryController.calcTotalKcal();
      },
      error: function(data) {
        console.log("Error loading food details");
      }
    });
  }

  calcTotalKcal() {
    let total = (parseFloat(this.quantityTarget.value)/100)*parseFloat(this.kcalTarget.value);
    this.kcal_totalTarget.value = total.toFixed(0);
  }

  deleteConfirm() {
    let td = event.target.closest('td');
    let uuid = td.dataset.uuid;
    let link1 = td.querySelector("a.text-default");
    let link2 = td.querySelector("span.delete")
    let link3 = td.querySelector("a.button.danger");
    let link4 = td.querySelector("span.cancel");
    link1.classList.add('is-hidden');
    link2.classList.add('is-hidden');
    link3.classList.remove('is-hidden');
    link4.classList.remove('is-hidden');
  }

  deleteCancel() {
    let td = event.target.closest('td');
    let uuid = td.dataset.uuid;
    let link1 = td.querySelector("a.text-default");
    let link2 = td.querySelector("span.delete")
    let link3 = td.querySelector("a.button.danger");
    let link4 = td.querySelector("span.cancel");
    link3.classList.add('is-hidden');
    link4.classList.add('is-hidden');
    link1.classList.remove('is-hidden');
    link2.classList.remove('is-hidden');
  }

  switchEntrySource() {
    if(event.target.dataset.diaryTarget == 'btn_food_custom') {
      this.btn_food_listTarget.classList.remove('primary');
      this.btn_food_customTarget.classList.add('primary');
      this.food_custom_unitTarget.classList.remove('is-hidden');
      this.food_listTarget.classList.add('is-hidden');
      this.food_customTarget.classList.remove('is-hidden');
      this.food_listTarget.querySelector("input").required = false;
      document.querySelector("#entry_name").required = true;
    } else {
      this.btn_food_customTarget.classList.remove('primary');
      this.btn_food_listTarget.classList.add('primary');
      this.food_custom_unitTarget.classList.add('is-hidden');
      this.food_customTarget.classList.add('is-hidden');
      this.food_listTarget.classList.remove('is-hidden');
      document.querySelector("#entry_name").required = false;
      document.querySelector("#entry_name").value = '';
      this.food_listTarget.querySelector("input").required = true;
    }
  }

  loadData() {
    let diaryController = this;

    Rails.ajax({
      url: window.location.pathname,
      type: "get",
      data: "",
      success: function(data) {
        if(diaryController.week_viewTarget.checked) {
          diaryController.loadWeekChart(data);
        } else {
          diaryController.loadDayChart(data);
        }
      },
      error: function(data) {
        console.log("Error loading diary data");
      }
    });
  }

  loadDayChart(data) {
    const config = {
      type: 'doughnut',
      data: data,
      options: {
        plugins: {
          legend: {
            labels: {
              font: {
                  size: 16
              }
            }
          },
          tooltip: {
            titleFont: {
              size: 20
            },
            bodyFont: {
              size: 16
            },
            callbacks: {
              title: function(tooltipItem, d) {
                return tooltipItem[0].label;
              },
              label: function(tooltipItem, d) {
                return ' '+tooltipItem.formattedValue + ' kcal';
              },
            }
          }
        }
      }
    };
    var diaryDayChart = new Chart(
      this.chartTarget,
      config
    );
  }

  loadWeekChart(data) {
    let maxValue = Math.max(...data.datasets[1].data)+200;

    let chartLocale;
    if(this.locale == 'de') {
      chartLocale = chartDe;
    } else if(this.locale == 'fr') {
      chartLocale = chartFr;
    } else if(this.locale == 'it') {
      chartLocale = chartIt;
    }

    const config = {
      type: 'line',
      data: data,
      options: {
        plugins: {
          title: {
            text: I18n.t('diary.week_overview'),
            display: true
          },
          tooltip: {
            titleFont: {
              size: 16
            },
            bodyFont: {
              size: 14
            },
            callbacks: {
              title: function(item) {
                return item[0].dataset.label;
              },
              label: function(item) {
                return ' '+item.formattedValue + ' kcal';
              },
            }
          }
        },
        scales: {
          y: {
            ticks: {
              callback: function(value, index, values) {
                return value + ' kcal';
              }
            },
            suggestedMax: maxValue
          },
          x: {
            type: 'time',
            display: true,
            offset: true,
            adapters: {
              date: {
                locale: chartLocale
              }
            },
            time: {
              unit: 'day',
              displayFormats: {
                day: 'd MMM'
              }
            }
          },
        }
      }
    };

    var diaryWeekChart = new Chart(
      this.chartTarget,
      config
    );

/*
    var bars = diaryWeekChart.datasets[0].bars;
    for(i=0;i<bars.length;i++){
       var color="green";
       //You can check for bars[i].value and put your conditions here
       bars[i].fillColor = color;

    }
    */
  }

  setView() {
    Rails.ajax({
      url: `/${this.locale}/user/config/set/diary_view/${event.target.value}`,
      type: "post",
      data: "",
      success: function(data) {
        location.reload();
      },
      error: function(data) {
        console.log("Error loading diary data");
      }
    });
  }
}
