import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "reviews" ]

  initialize() {
  }

  connect() {
    this.initReviews()
  }

  initReviews() {
    this.reviewPos = 0;
    this.reviewDivs = this.reviewsTarget.querySelectorAll('.content');
    this.reviewDivs.forEach((div, i) => {
      if(i > 0) {
        div.style.display = 'none';
      }
    });
    this.slideReviews();
  }

  slideReviews() {
    let that = this;
    this.reviewTimeout = setTimeout(() => {
      if(this.reviewPos < this.reviewDivs.length-1) {
        $(this.reviewDivs[this.reviewPos]).fadeOut(400, function() {
          that.reviewPos += 1;
          $(that.reviewDivs[that.reviewPos]).fadeIn(400);
        });
      } else {
        $(this.reviewDivs[this.reviewPos]).fadeOut(400, function() {
          that.reviewPos = 0;
          $(that.reviewDivs[that.reviewPos]).fadeIn(400);
        });
      }
      that.slideReviews()
    }, 15000)
  }

  previousReview() {
    clearTimeout(this.reviewTimeout)
    let that = this;
    if(this.reviewPos > 0) {
      $(this.reviewDivs[this.reviewPos]).fadeOut(400, function() {
        that.reviewPos -= 1;
        $(that.reviewDivs[that.reviewPos]).fadeIn(400);
      });
    } else {
      $(this.reviewDivs[this.reviewPos]).fadeOut(400, function() {
        that.reviewPos = that.reviewDivs.length-1;
        $(that.reviewDivs[that.reviewPos]).fadeIn(400);
      });
    }
    this.slideReviews();
  }

  nextReview() {
    clearTimeout(this.reviewTimeout)
    let that = this;
    if(this.reviewPos < this.reviewDivs.length-1) {
      $(this.reviewDivs[this.reviewPos]).fadeOut(400, function() {
        that.reviewPos += 1;
        $(that.reviewDivs[that.reviewPos]).fadeIn(400);
      });
    } else {
      $(this.reviewDivs[this.reviewPos]).fadeOut(400, function() {
        that.reviewPos = 0;
        $(that.reviewDivs[that.reviewPos]).fadeIn(400);
      });
    }
    this.slideReviews();
  }
}
