import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "picture", "pictureWrapper", "pictureDescription" ]

  initialize() {

  }

  connect() {

  }

  showImage() {
    this.pictureTarget.src = event.target.dataset.image;
    this.pictureDescriptionTarget.innerHTML = event.target.alt;
    this.pictureWrapperTarget.classList.remove('is-hidden');
  }

  hideImage() {
    this.pictureWrapperTarget.classList.add('is-hidden');
  }
}
