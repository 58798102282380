import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "form", "btnSearch" ]

  initialize() {

  }

  connect() {
    let controller = this;

    this.formTarget.addEventListener('submit', () => {
      this.btnSearchTarget.querySelector('i').classList.remove('is-hidden');
      this.btnSearchTarget.classList.add('disabled');
      this.btnSearchTarget.disabled = true;
    })

    window.recipesLoadingFinished = function() {
      controller.btnSearchTarget.querySelector('i').classList.add('is-hidden');
      controller.btnSearchTarget.classList.remove('disabled');
      controller.btnSearchTarget.disabled = false;
    }
  }

  showMore() {
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "recipes[offset]";
    input.value = '10';
    this.formTarget.appendChild(input);
    Rails.fire(this.formTarget, 'submit');
  }

}
