import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "image_picker", "image_wrapper", "image", "imageSid" ]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }

    if(this.element.getAttribute("data-uuid")) {
      this.storyResizeImages();
    }

    if(this.element.getAttribute("data-successstory-uuid")) {
      this.storyUuid = this.element.getAttribute("data-successstory-uuid");
    }
  }

  connect() {
    if(this.storyUuid) {
      setTimeout(() => {
        scroll({
          top: (0)
        });
      }, 100);
    }
  }

  storyResizeImages() {
    let story = document.querySelector(".container.success_story");
    let figures = story.querySelectorAll('figure');
    figures.forEach((figure, i) => {
      figure.classList.add('image', 'fit');
    });

  }

  selectImage() {
    this.image_pickerTarget.click();
  }

  removeImage() {
    this.imageTarget.src = '';
    this.image_wrapperTarget.classList.add('is-hidden');
    this.imageSidTarget.value = '';
  }

  uploadImage() {
    let that = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;

    var files = input.files.length;
    var count = 0;

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/successstory/images/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              that.imageTarget.src = data.preview;
              that.image_wrapperTarget.classList.remove('is-hidden');
              that.imageSidTarget.value = data.sid;
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

}
