import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "chart" ]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    this.loadData();
  }

  loadData() {
    let homeController = this;

    Rails.ajax({
      url: `/${this.locale}/home`,
      type: "get",
      data: "",
      success: function(data) {
        homeController.loadChart(data);
      },
      error: function(data) {
        console.log("Error loading diary data");
      }
    });
  }

  loadChart(data) {
    const config = {
      type: 'doughnut',
      data: data,
      options: {
        plugins: {
          legend: {
            labels: {
              font: {
                  size: 16
              }
            }
          },
          tooltip: {
            titleFont: {
              size: 20
            },
            bodyFont: {
              size: 16
            },
            callbacks: {
              title: function(tooltipItem, d) {
                return tooltipItem[0].label;
              },
              label: function(tooltipItem, d) {
                return ' '+tooltipItem.formattedValue + ' kcal';
              },
            }
          }
        }
      }
    };

    var homeChart = new Chart(
      this.chartTarget,
      config
    );
  }

}
