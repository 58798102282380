import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "minutes", "kcal", "kcal_total", "btn_exercises", "btn_workouts", "uuid", "exercise"]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    if(this.hasUuidTarget) {
      this.kcalTarget.value = this.exerciseTarget.options[this.exerciseTarget.selectedIndex].dataset.calories;
    }
  }

  setExercises() {
    this.minutesTarget.value = 15;
    this.kcalTarget.value = event.target.dataset.calories;
    this.calcTotalKcal();
  }

  calcTotalKcal() {
    let total = (parseFloat(this.minutesTarget.value)/15)*parseFloat(this.kcalTarget.value);
    this.kcal_totalTarget.value = total.toFixed(0);
  }

  deleteConfirm() {
    let td = event.target.closest('td');
    let uuid = td.dataset.uuid;
    let link1 = td.querySelector("a.text-default");
    let link2 = td.querySelector("span.delete")
    let link3 = td.querySelector("a.button.danger");
    let link4 = td.querySelector("span.cancel");
    link1.classList.add('is-hidden');
    link2.classList.add('is-hidden');
    link3.classList.remove('is-hidden');
    link4.classList.remove('is-hidden');
  }

  deleteCancel() {
    let td = event.target.closest('td');
    let uuid = td.dataset.uuid;
    let link1 = td.querySelector("a.text-default");
    let link2 = td.querySelector("span.delete")
    let link3 = td.querySelector("a.button.danger");
    let link4 = td.querySelector("span.cancel");
    link3.classList.add('is-hidden');
    link4.classList.add('is-hidden');
    link1.classList.remove('is-hidden');
    link2.classList.remove('is-hidden');
  }

  switchEntrySource() {
    if(event.target.dataset.diaryTarget == 'btn_workouts') {
      this.btn_food_listTarget.classList.remove('primary');
      this.btn_food_customTarget.classList.add('primary');
      this.food_custom_unitTarget.classList.remove('is-hidden');
      this.food_listTarget.classList.add('is-hidden');
      this.food_customTarget.classList.remove('is-hidden');
      this.food_listTarget.querySelector("input").required = false;
      document.querySelector("#entry_name").required = true;
    } else {
      this.btn_food_customTarget.classList.remove('primary');
      this.btn_food_listTarget.classList.add('primary');
      this.food_custom_unitTarget.classList.add('is-hidden');
      this.food_customTarget.classList.add('is-hidden');
      this.food_listTarget.classList.remove('is-hidden');
      document.querySelector("#entry_name").required = false;
      document.querySelector("#entry_name").value = '';
      this.food_listTarget.querySelector("input").required = true;
    }
  }

}
