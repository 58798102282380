import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }

    if(this.element.getAttribute("data-review-uuid")) {
      this.reviewUuid = this.element.getAttribute("data-review-uuid");
    }
  }

  connect() {
    if(this.reviewUuid) {
      setTimeout(() => {
        scroll({
          top: (0)
        });
      }, 100);
    }
  }
}
