import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "section1", "section2", "section3", "section4", "section5", "radio1", "btnBack", "btnNext", "btnCalculate", "radio2", "radio3", "radio4", "age", "height", "weight", "weight_current", "weight_desired", "results_wrapper", "results", "result_kcal", "result_bmi", "weight_loss_plan", "weight_loss", "weight_loss_weeks", "weight_loss_date", "progress_section_2", "progress_section_3", "progress_section_4", "buttons" ]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    this.step = 1;
    $(this.section2Target).hide();
    $(this.section3Target).hide();
    $(this.section4Target).hide();
    $(this.section5Target).hide();
    $(this.weight_lossTarget).hide();
  }

  nextStep() {
    ++this.step;

    switch(this.step) {
      case 2:
        this.stepTwo();
      break;
      case 3:
        this.stepThree();
      break;
      case 4:
        this.btnNextTarget.classList.add('is-hidden');
        this.stepFour();
        this.btnCalculateTarget.classList.remove('is-hidden');
      break;
    }
  }

  previousStep() {
    if(this.step == 1) return;

    --this.step;

    switch(this.step) {
      case 1:
        this.backStepOne();
      break;
      case 2:
        this.backStepTwo();
      break;
      case 3:
        this.btnCalculateTarget.classList.add('is-hidden');
        this.backStepThree();
        this.btnNextTarget.classList.remove('is-hidden');
      break;
    }
  }

  stepTwo() {
    this.radio1 = null;
    for (let i = 0; i < 4; i++) {
      let radio = this.radio1Targets[i];
      if(radio.checked) {
        this.radio1 = i;
      }
    }
    let controller = this;
    $(this.section1Target).fadeOut('fast', () => {
      $(controller.section2Target).fadeIn('fast');
    });
    this.progress_section_2Target.classList.add('active');
  }

  stepThree() {
    this.radio2 = null;
    for (let i = 0; i < 4; i++) {
      let radio = this.radio2Targets[i];
      if(radio.checked) {
        this.radio2 = i;
      }
    }
    let controller = this;
    $(this.section2Target).fadeOut('fast', () => {
      $(controller.section3Target).fadeIn('fast');
    });
    this.progress_section_3Target.classList.add('active');
  }

  stepFour() {
    this.radio3 = null;
    for (let i = 0; i < 3; i++) {
      let radio = this.radio3Targets[i];
      if(radio.checked) {
        this.radio3 = i;
      }
    }
    let controller = this;
    $(this.section3Target).fadeOut('fast', () => {
      $(controller.section4Target).fadeIn('fast');
    });
    this.progress_section_4Target.classList.add('active');
  }

  backStepOne() {
    let controller = this;
    $(this.section2Target).fadeOut('fast', () => {
      $(controller.section1Target).fadeIn('fast');
    });
    this.progress_section_2Target.classList.remove('active');
  }

  backStepTwo() {
    let controller = this;
    $(this.section3Target).fadeOut('fast', () => {
      $(controller.section2Target).fadeIn('fast');
    });
    this.progress_section_3Target.classList.remove('active');
  }

  backStepThree() {
    let controller = this;
    $(this.section4Target).fadeOut('fast', () => {
      $(controller.section3Target).fadeIn('fast');
    });
    this.progress_section_4Target.classList.remove('active');
  }

  setOption() {
    switch(event.target.dataset.section) {
      case "1":
        this.radio1Targets[event.target.dataset.option-1].checked = true;
        var options = event.target.parentNode.parentNode.querySelectorAll('.option');
        for(let i = 0; i < options.length; i++) {
          options[i].querySelector(".label").classList.remove('active');
        }
        event.target.querySelector(".label").classList.add('active');
        break;
      case "2":
        this.radio2Targets[event.target.dataset.option-1].checked = true;
        var options = event.target.parentNode.parentNode.querySelectorAll('.option');
        for(let i = 0; i < options.length; i++) {
          options[i].querySelector(".label").classList.remove('active');
        }
        event.target.querySelector(".label").classList.add('active');
        break;
      case "3":
        this.radio3Targets[event.target.dataset.option-1].checked = true;
        var options = event.target.parentNode.parentNode.querySelectorAll('.option');
        for(let i = 0; i < options.length; i++) {
          options[i].querySelector(".label").classList.remove('active');
        }
        event.target.querySelector(".label").classList.add('active');
        break;
      case "4":
        this.radio4Targets[event.target.dataset.option-1].checked = true;
        var options = event.target.parentNode.parentNode.querySelectorAll('.option');
        for(let i = 0; i < options.length; i++) {
          options[i].querySelector(".label").classList.remove('active');
        }
        event.target.querySelector(".label").classList.add('active');
        break;
    }
  }

  checkInput() {
    let age = this.ageTarget.value;
    let height = this.heightTarget.value;
    let weight = this.weightTarget.value;

    let valid = true;

    if(weight = null || weight < 30 || weight > 500) {
      valid = false;
      this.weightTarget.classList.add('has-error-border');
      this.weightTarget.focus();
    } else {
      this.weightTarget.classList.remove('has-error-border');
    }

    if(height = null || height < 100 || height > 230) {
      valid = false;
      this.heightTarget.classList.add('has-error-border');
      this.heightTarget.focus();
    } else {
      this.heightTarget.classList.remove('has-error-border');
    }

    if(age = null || age < 13 || age >= 120) {
      valid = false;
      this.ageTarget.classList.add('has-error-border');
      this.ageTarget.focus()
    } else {
      this.ageTarget.classList.remove('has-error-border');
    }


    if(valid) {
      this.buttonsTarget.classList.add('is-hidden');
      this.calculate();
    }
  }

  calculate() {
    let height = this.heightTarget.value;
    let weight = this.weightTarget.value;
    let kcal = parseFloat(weight)*24;
    let bmi = parseFloat(weight)/(parseFloat(height)/100*2).toFixed(1);

    for(let i = 0; i < 4; i++) {
      if(this.radio1Targets[i].checked) {
        kcal = kcal * parseFloat(this.radio1Targets[i].value);
      }
    }

    for(let i = 0; i < 4; i++) {
      if(this.radio2Targets[i].checked) {
        kcal = kcal * parseFloat(this.radio2Targets[i].value);
      }
    }

    for(let i = 0; i < 3; i++) {
      if(this.radio3Targets[i].checked) {
        kcal = kcal * parseFloat(this.radio3Targets[i].value);
      }
    }

    if(document.querySelector("#section_4_female").checked) {
      kcal = kcal * .9;
    }

    let controller = this;
    this.result_kcalTarget.innerHTML = `${parseInt(kcal)} kcal`;
    this.result_bmiTarget.innerHTML = `${bmi.toFixed(1)}`;
    this.weight_currentTarget.value = weight;
    this.weight_desiredTarget.focus();

    if(bmi.toFixed(1) <= 18) {
      this.result_bmiTarget.classList.add('text-warning');
    }
    else if(bmi.toFixed(1) > 18 && bmi.toFixed(1) < 25) {
      this.result_bmiTarget.classList.add('text-success');
    }
    else if(bmi.toFixed(1) >= 25 && bmi.toFixed(1) < 29) {
      this.result_bmiTarget.classList.add('text-warning');
    }
    else if(bmi.toFixed(1) >= 29) {
      this.result_bmiTarget.classList.add('text-danger');
    }

    $(this.section4Target).fadeOut('fast', () => {
      $(controller.section5Target).fadeIn('fast');
    });
  }

  calcWeightLoss() {
    let controller = this;

    let weight1 = this.weight_currentTarget.value;
    let weight2 = this.weight_desiredTarget.value;

    let today = new Date();
    let enddate = new Date();

    let difference = 0;
    if(parseInt(weight2) > parseInt(weight1)) {
      difference = parseInt(weight2) - parseInt(weight1);
    } else {
      difference = parseInt(weight1) - parseInt(weight2);
    }

    var options = {year: 'numeric', month: 'long', day: 'numeric' };
    let weeks = Math.ceil(difference/.5);
    this.weight_loss_weeksTarget.innerHTML = weeks;
    enddate.setDate(today.getDate() + (weeks*7));
    this.weight_loss_dateTarget.innerHTML = enddate.toLocaleDateString(this.locale, options);
    $(this.weight_loss_planTarget).fadeOut('fast', () => {
      $(controller.weight_lossTarget).fadeIn('fast');
    });
  }
}
