import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "email1", "email2", "email_mismatch", "password1", "password2", "password_mismatch", "password_short", "option_gender_male", "option_gender_female", "radio_gender_female", "radio_gender_male", "activity_water", "activity_everyday", "activity_recreation", "signup_subscription_3", "signup_subscription_6", "signup_subscription_12", "reach_desired_weight", "weight_current", "weight_desired", "payment_method_card", "payment_method_postcard", "payment_method_twint" ]

  connect() {
    if(this.hasReach_desired_weightTarget) {
      this.calcDate();
    }
  }

  signupCheckEmail() {
    let email1 = this.email1Target.value;
    let email2 = this.email2Target.value;
    if(email1 != email2) {
      this.email1Target.classList.add('has-error-border');
      this.email2Target.classList.add('has-error-border');
      this.email_mismatchTarget.classList.remove('is-hidden');
    } else {
      this.email1Target.classList.remove('has-error-border');
      this.email2Target.classList.remove('has-error-border');
      this.email_mismatchTarget.classList.add('is-hidden');
    }
  }

  signupCheckPassword() {
    let password = this.password1Target.value;
    let confirmation = this.password2Target.value;

    this.password1Target.classList.remove('has-error-border');
    this.password2Target.classList.remove('has-error-border');
    this.password_mismatchTarget.classList.add('is-hidden');
    this.password_shortTarget.classList.add('is-hidden');

    if(password != confirmation) {
      this.password1Target.classList.add('has-error-border');
      this.password2Target.classList.add('has-error-border');
      this.password_mismatchTarget.classList.remove('is-hidden');
    }
    if(password.length < 8) {
      this.password1Target.classList.add('has-error-border');
      this.password2Target.classList.add('has-error-border');
      this.password_shortTarget.classList.remove('is-hidden');
    }
  }

  showOverlay() {
    let body = document.querySelector('body');
    let overlay = document.querySelector('#overlay');
    if(overlay) {
      overlay.style.display = 'flex';
    }
    body.style.overflowY = 'hidden';
  }

  setProfileGender() {
    if(event.target.dataset.option == 'male') {
      this.radio_gender_maleTarget.checked = true;
      this.option_gender_femaleTarget.classList.remove('active');
      this.option_gender_maleTarget.classList.add('active');
    } else {
      this.radio_gender_femaleTarget.checked = true;
      this.option_gender_maleTarget.classList.remove('active');
      this.option_gender_femaleTarget.classList.add('active');
    }
  }

  calcBMIKcal() {
    let height = document.querySelector("#data_height").value;
    let weight = document.querySelector("#data_weight").value;
    if(height != '' && parseInt(height) != '' && weight != '' && parseInt(weight) != 0) {
      let bmi = parseFloat(weight)/(parseFloat(height)/100*2);
      document.querySelector("#data_bmi").value = bmi.toFixed(1);
    }

    let kcal = parseFloat(weight)*24;

    switch(this.activity_recreationTarget.value) {
      case '2':
        kcal = kcal * 1.02;
        break;
      case '3':
        kcal = kcal * 1.04;
        break;
      case '4':
        kcal = kcal * 1.06;
        break;
    }

    switch(this.activity_everydayTarget.value) {
      case '2':
        kcal = kcal * 1.02;
        break;
      case '3':
        kcal = kcal * 1.04;
        break;
      case '4':
        kcal = kcal * 1.06;
        break;
    }

    switch(this.activity_waterTarget.value) {
      case '2':
        kcal = kcal * 1.02;
        break;
      case '3':
        kcal = kcal * 1.04;
        break;
    }

    if(this.radio_gender_femaleTarget.checked) {
      kcal = kcal * .9;
    }

    document.querySelector("#data_daily_kcal").value = parseInt(kcal);
  }

  signupSetSubscription() {
    let div = event.target;
    if(!div.dataset.month) {
      div = div.parentNode;
    }
    this.signup_subscription_3Target.classList.remove('active');
    this.signup_subscription_6Target.classList.remove('active');
    this.signup_subscription_12Target.classList.remove('active');
    document.querySelector("#subscription_months").value = div.dataset.month;
    switch(div.dataset.month) {
      case '3':
        this.signup_subscription_3Target.classList.add('active');
      break;
      case '6':
        this.signup_subscription_6Target.classList.add('active');
      break;
      case '12':
        this.signup_subscription_12Target.classList.add('active');
      break;
    }
  }

  signupSetPaymentmethod() {
    let div = event.target;
    if(!div.dataset.method) {
      div = div.parentNode;
    }
    this.payment_method_cardTarget.classList.remove('active');
    this.payment_method_postcardTarget.classList.remove('active');
    this.payment_method_twintTarget.classList.remove('active');
    document.querySelector("#payment_method").value = div.dataset.method;
    switch(div.dataset.method) {
      case 'card':
        this.payment_method_cardTarget.classList.add('active');
      break;
      case 'postcard':
        this.payment_method_postcardTarget.classList.add('active');
      break;
      case 'twint':
        this.payment_method_twintTarget.classList.add('active');
      break;
    }
  }

  calcDate() {
    let weight1 = this.weight_currentTarget.value;
    let weight2 = this.weight_desiredTarget.value;

    if(weight2 && weight2 > 0) {

      let today = new Date();
      let enddate = new Date();

      let difference = 0;
      if(parseInt(weight2) > parseInt(weight1)) {
        difference = parseInt(weight2) - parseInt(weight1);
      } else {
        difference = parseInt(weight1) - parseInt(weight2);
      }

      var options = {year: 'numeric', month: 'long', day: 'numeric' };
      let weeks = Math.ceil(difference/.5);
      enddate.setDate(today.getDate() + (weeks*7));
      this.reach_desired_weightTarget.innerHTML = enddate.toLocaleDateString(this.locale, options);

      this.reach_desired_weightTarget.innerHTML += `&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;${I18n.t('date.in_weeks', {weeks: weeks})}`;
    }
  }
}
